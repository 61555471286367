import styled from 'styled-components';

import { Color } from '@lerna-monorepo/common/theme';
import { CaseStudySection } from '@lerna-monorepo/common/components/caseStudySection/caseStudySection.component';
import { ProductsSliderSection } from '@lerna-monorepo/common/components/productsSliderSection/productsSliderSection.component';

export const DynamicContentComponentContainer = styled.div`
  & + & {
    padding-top: 100px;
  }
`;

export const DynamicContentGroupContainer = styled.div`
  padding: 150px 0;
  background-color: ${Color.veryLightGrey2};
  position: relative;
`;

export const LocalCaseStudySection = styled(CaseStudySection)``;

export const LocalProductsSection = styled(ProductsSliderSection)`
  padding-top: 100px;
`;

export const RelatedCaseStudyRelatedProductsContainer = styled.div`
  background-color: ${Color.white};
  padding: 150px 0;
  position: relative;
`;

export const HalfBox = styled.div`
  width: 50%;
  background-color: ${Color.white};
  height: 45px;
  position: absolute;
  bottom: 0;
  right: 0;
`;
