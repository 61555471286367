import React from 'react';

import {
  DynamicContentComponent
} from '@lerna-monorepo/common/components/dynamicContentComponent/dynamicContentComponent.component';
import { Color } from '@lerna-monorepo/common/theme';
import { PageCategory } from '@lerna-monorepo/common/enums/pageCategory.enum';
import { ProductPageHeader } from '@lerna-monorepo/common/components/productPageHeader/productPageHeader.component';
import { DynamicContent } from '@lerna-monorepo/common/interfaces/dynamicContent.types';
import { renderWhenTrue } from '@lerna-monorepo/common/utils';
import { MapSection } from '@lerna-monorepo/common/components/mapSection/mapSection.component';

import {
  DynamicContentComponentContainer,
  DynamicContentGroupContainer,
  HalfBox,
} from './briquette.styled';
import { BriquettePageData } from './briquette.types';

const BriquettePage: React.FC<BriquettePageData> = (pageData: BriquettePageData) => {
  const { data, currentLanguage, salePoints } = pageData.pageContext;
  const {
    productHeaderSection,
    productContent,
    saleMapSectionBottom,
  } = data;
  const {
    productImagesGallery,
    header,
    subheader,
    productDescription,
    leftButton,
    rightButton,
  } = productHeaderSection;
  const transformedProductImagesGallery = productImagesGallery.map((productImageUrl: string) => ({
    localFile: { url: productImageUrl },
  }));

  const renderContent = () => productContent.map((productContentItem: DynamicContent) => (
    <DynamicContentComponentContainer
      key={`${productContentItem.header.bigHeader.mainContent}${productContentItem.header.bigHeader.descriptionContent}`}
    >
      <DynamicContentComponent
        header={productContentItem.header}
        dynamicContent={productContentItem.dynamicContent}
        currentLanguage={currentLanguage}
      />
    </DynamicContentComponentContainer>
  ));

  const renderMapSection = renderWhenTrue(() => (
    <DynamicContentComponentContainer>
      <MapSection
        sectionHeader={saleMapSectionBottom.sectionHeader}
        sectionSubheader={saleMapSectionBottom.sectionSubheader}
        salePoints={salePoints}
        currentLanguage={currentLanguage}
      />
    </DynamicContentComponentContainer>
  ));

  return (
    <>
      <ProductPageHeader
        productImages={transformedProductImagesGallery}
        header={header}
        subheader={subheader}
        productDescription={productDescription}
        leftButton={leftButton}
        rightButton={rightButton}
        currentLanguage={currentLanguage}
      />
      <DynamicContentGroupContainer>
        {renderContent()}
        {renderMapSection(saleMapSectionBottom.isEnabled)}
      </DynamicContentGroupContainer>
    </>
  );
};

export default BriquettePage;
